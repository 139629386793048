import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="err404">
            <h1><span>404:</span> Not Found</h1>
            <p>Ups, la pagina que buscas no existe.</p>
            <a className="btn-primary" href="/">Volver</a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
